<template>
  <div class="mobiletopmargin">
    <div class="container-fluid third-container-fluid puff-in-center">
        <div class="container p-0">
            <div class="row message-container">
                <div class="w-50 message-box d-md-none">
                    <h1 class="h1-in-message m-0 p-0">DONATE<span class="text-white"></span></h1>
                    <h1 class="text-white h1-in-msg mb-4 p-0 fw-normal">FOR A BETTER<span class="span-in-message"> WORLD</span></h1>
                    <hr class="h-row mb-4 pt-2">
                    <button class="btn btn-sm text-white donate-now" data-bs-toggle="modal" href="#Volunteer" role="button">BECOME A VOLUNTEER</button>
                </div>
                <div class="w-50 message-box d-none d-md-block">
                    <h1 class="h1-in-message m-0 p-0">DONATE<span class="text-white"> FOR A </span></h1>
                    <h1 class="text-white h1-in-message mb-3 p-0 fw-normal">BETTER<span class="span-in-message"> WORLD</span>
                    </h1>
                    <hr class="h-row mb-2 pt-2 ">
                    <p class="p-0 text-white fw-light mb-3">KEI primarily supports academically brilliant, though financially weak students in the 
                        valley. Our main focus is to identify the deserving students from the early stages of their 
                        schooling and handhold them to acquire necessary skills in the premier institutions in 
                        India and abroad to be placed for careers in the top notch institutions/ companies in the 
                        world. 
                    </p>
                    <div>
                        <button class="btn btn-lg text-white donate-now puff-in-center" data-bs-toggle="modal" href="#Donate1" role="button">DONATE NOW</button>
                        <button class="btn btn-lg  raised-funds text-white" data-bs-toggle="modal" href="#Volunteer" role="button">BECOME VOLUNTEER</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Web Notification -->
        <NotificationWeb v-for="(notification, index) in notifications" :data="notification" :key="index" />
        <!-- Web Notification -->  


    </div>
       

    
    <div class="container-fluid">


        <!-- Mobile Notification -->
        <NotificationMobile v-for="(notification, index) in notifications" :data="notification" :key="index" />
        <!-- Mobile Notification -->




        <div class="container">
            <div class="row bigboxes">
                <!-- <div class="container">
                    <div class="row d-flex justify-content-md-between justify-content-center">
                    <div class="small-div-box1">
                        <i class="fas fa-hourglass midicon text-white"></i>
                        <p class="mb-0 p-0 text-white fw-light mt-2">Founded</p>
                        <h1 class=" mb-0 p-0 text-white fw-bold">2009</h1>
                    </div>
                    <div class="small-div-box">
                        <i class="fas fa-star midicon"></i>
                        <p class="mb-0 p-0 fw-light mt-2">Rated</p>
                        <h1 class="mb-0 p-0 fw-bold">4.9<span class="span-in-div fw-normal">+</span></h1>
                    </div>
                    <div class="small-div-box">
                        <i class="fas fa-percent midicon"></i>
                        <p class="mb-0 p-0 fw-light mt-2">Active Volunteers</p>
                        <h1 class="mb-0 p-0 fw-bold">99<span class="span-in-div fw-normal">%</span></h1>
                    </div>
                    <div class="small-div-box">
                        <i class="fas fa-handshake midicon"></i>
                        <p class="mb-0 p-0 fw-light mt-2">Volunteers Registered</p>
                        <h1 class="mb-0 p-0 fw-bold">200<span class="span-in-div fw-normal">+</span></h1>
                    </div>
                    </div> -->
                <div class="row flex-column-reverse flex-md-row mobilepadding pb-5">
                    <div class="col-12 col-md-6">
                    <img src="assets/images/Group 1259.png" class="div-image">
                    </div>
                    <div class="col-12 col-md-6 initiative-box">
                    <img src="assets/images/Path 1001.png" class="div-image-2">
                    <h3 class="fw-bold mt-3">Welcome To Kei</h3>
                    <hr class="h-row-2 mt-1 pt-2">
                    <p class="p-0">
                        KEI is a registered, non-political, non-profit and charitable organization with a goal to provide a 
                        thriving environment to the students in Kashmir. A major goal of the organization is to support 
                        financially weak but academically brilliant students during their studies. We identify students at 
                        different levels of their careers (graduate and post-graduate) through a well-defined, multilayered 
                        screening process and provide them with financial support. In addition, we also provide career 
                        guidance and mentorship to the student community, irrespective of their financial needs. The 
                        major source of our fund-raising is through the kind donations made by our donors. We meet all 
                        the regulations of our operations and income/expenditures as stipulated by the government. We 
                        also have the 80(G) status that the donors can use for their income tax return rebates
                    </p>
                    <button class="btn btn-lg rounded-pill read-more text-white mt-4">Read More</button>
                    </div>
                </div>
                <!-- <div class="row mobilepadding">
                    <h2 class="text-center h2-blog-line">Latest From Our Blog</h2>
                    <hr class="p-1 h-row-3">
                    </div>
                    <div class="row d-flex justify-content-between mt-4 mobilepadding" v-if="blogs != undefined" >
                    <div class="image-div" v-for="(blog, index) in blogs" :key="index">
                    <h5 class="p-3 m-0 fw-bold">{{ blog.title.substring(0,50) }}<span v-if="blog.title.length>50">...</span> </h5>
                    <img :src="`${apiURL}${blog.image.url}`" class="w-100">
                    <router-link :to="`/blog/${blog.id}`" class="d-flex text-decoration-none align-items-center read-more slash text-white my-3 fs-14">Read More</router-link>
                    </div>
                    </div> -->
                <!-- <div class="row">
                    <h2 class="text-center h2-blog-line">Donor <span class="fw-light">Testimonials</span></h2>
                    <hr class="p-1 h-row-3">
                    </div> -->
            </div>
        </div>
    </div>
    <!-- <div class="container-fluid fifth-container-fluid mt-3">
        <div class="container">
            <div class="row d-flex justify-content-center justify-content-md-between div-container">
            <div class="taruk-div-width" v-for="(testmonial,index) in testmonials" :key="index">
                <img :src="`${apiURL}${testmonial.image.url}`" width="100px" height="100px">
                <div class="rating">
                <i v-for="n in 5" :key="n" :class="(testmonial.rating >= n) ? 'fas fa-star text-warning' : 'far fa-star text-warning'" ></i>
                </div>
                <h5 class="text-white mt-3 fw-bold">{{testmonial.name}}</h5>
                <i class="text-white mt-0">{{testmonial.designation}}</i>
                <div class="d-flex align-items-center mt-3">
                <p class="p-0 ms-2 mb-0 lh-1 text-white fw-light text-center">"{{testmonial.review}}"</p>
                </div>
            </div>
            </div>
        </div>
        </div> -->
    <!-- <div class="row">
        <h1 class="text-center h2-blog-line">Our <span class="fw-light fs-2">Scholars </span></h1>
        <hr class="p-1 h-row-3">
        </div> -->
    <!-- <div class="container-fluid sixth-container-fluid">
        <div class="container">
            <div class="row d-flex justify-content-center">
            <div class="w-25 simran-img-div">
                <div class="bg-white">
                <img src="assets/images/cause-1.png" class="w-50 simran-img bg-white">
                </div>
                <div class="simran">
                <p class="fw-bold mb-0 text-white">"In Kashmir,there is a new conflict<br>Everyday"</p>
                <p class="fw-light mb-0 conflict-para text-white mt-2">Though 16-year-old Simran dreamed of being a journalist, the onset of her father’s debilitating intestinal disorder inspired a change
        in her hopes for the future. This KEI scholar currently has her
        mind set on becoming a gastroenterologist. She admits, however,
        that her parents’ fears for her journalistic career may have also
        played a part  </p>
                </div>
            </div>
            <div class="w-25 simran-img-div">
                <div class="bg-white">
                <img src="assets/images/cause-2.jpg" class="w-50 simran-img bg-white">
                </div>
                <div class="simran">
                <p class="fw-bold mb-0 text-white">Artsist's dream to work at Pixar .
        <br>Animation Studio</p>
                <p class="fw-light mb-0 conflict-para text-white mt-2">17-year-old Faisal wasn’t alive to witness Kashmir’s booming film
        culture, but nonetheless managed to cultivate a passion for
        animation through watching DreamWorks and Pixar films online.
        He counts “Brave” and “Coco” among his all-time favorites. Using
        a laptop KEI’s Self Study program provided him, he’s able to watch
        the films that inspire himt</p>
                </div>
            </div>
            <div class="w-25 simran-img-div">
                <div class="bg-white">
                <img src="assets/images/cause-3.jpg" class="w-50 simran-img bg-white">
                </div>
                <div class="simran">
                <p class="fw-bold mb-0 text-white">“Built her own aircraft at just 13” <br>Arbeena tells us"</p>
                <p class="fw-light mb-0 conflict-para text-white mt-2">Sitting in her family home perched on the banks at the back of Dal
        Lake—her father’s shikara, a cornerstone of the family’s livelihood,
        moored a few feet away—Arbeena, a 17-year-old KEI scholar, recounted many interactions with tourists from far and wide. These
        interactions were facilitated by her father, who is a local craftsman. </p>
                </div>
            </div>
            </div>
        </div>
        </div> -->
</div>

</template>

<script>
import axios from 'axios';
import NotificationWeb from './common/NotificationWeb';
import NotificationMobile from './common/NotificationMobile';

  export default {
    name: 'Home',
    props: {
      msg: String
    },
    components: { NotificationWeb, NotificationMobile },
    data() {
      return {
          apiURL,
          blogs: [],
          testmonials:[],
          notifications: [
            { latest: true, title: 'Slection List', detail: 'Post Graduate Scholarship selection list', link: './notifications/kei_pgs_selection_list.pdf', linkTitle: 'DOWNLOAD NOW' },
          ],
      }
    },
    created() {
      
    },
    mounted() {  
      Fire.$emit('navigate'); 
      this.init();
      this.getTestmonials();
    },
    methods: {
      init() {
        axios.get(`${apiURL}/posts?_sort=id:DESC&_limit=3`).then((data)=> {
            this.blogs = data.data;
        }).catch(()=> {

        });   
      },
      getTestmonials(){
        axios.get(`${apiURL}/testmonials?_limit=4`).then(
          (data) => {
            this.testmonials = data.data
          }
        ).catch(

        )
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.notification{
  background: rgb(255,255,255);
background: linear-gradient(126deg, rgba(255,255,255,0) 40%, rgba(74,74,74,1) 40%);
  opacity: 0.9;
}
  .bigboxes{
    position:relative;
    z-index: 5554;
  }
    .third-container-fluid{
        background-image: url("../../public/assets/images/exhib.jpeg"); 
        width: 100%;  
        background-size: cover;
        background-repeat:no-repeat;
    }
    .message-box{
        background-color: #4a4a4a;
        padding: 1.5rem 2rem;
        opacity: .9;
    }
    .message-container{
        padding-top: 5rem;
    }
    .h1-in-message{
        font-size: 3rem;
        font-weight: 800;
        line-height: 0.9;
        color: #1baa79;
    }
    .span-in-message{
        color: #1baa79;
    }
    .h-row{
        color: #1baa79;
        width: 180px;
        opacity: 1;
        height: 3px;
    }
    .raised-funds{
        background-color:#4a4a4a;
        font-size: 15px;
        font-weight: 500;
        border: 2px solid #1baa79;
        border-radius: 0px;
    }
    .span-in-div{
        font-size: 24px;
    }
    .icon-image{
        width: 60px;
        height: 60px;
        background-color: white;
    }
    .small-div-box1{
        background-color:#1baa79;
        width: 20%;
        border-radius: 12px;
        padding: 28px;
        margin-top: -68px;
        margin-bottom: auto;
        box-shadow: 0px 3px 3px #1baa79;
    }
    .slash{
      clip-path: polygon(0 0, 72% 0, 100% 100%, 0% 100%);
      border:none;
      text-align: left;
      padding-left:15px;
    }
    .small-div-box{
        width: 20%;
        padding: 28px;
        border-radius: 12px;
        background-color: whitesmoke;
        margin-top: -68px;
        margin-bottom: auto;
        box-shadow: 0px 3px 3px #ced6d3;
    }
    .div-image{
        width: 100%;
        padding-top: 95px;
    }
    .div-image-2{
        padding-top: 150px;
    }
    .h-row-2{
        color: #1baa79;
        width: 100px;
        opacity: 1;
        height: 3px;
    }
    .read-more{
        background-color: #1baa79;
        font-size: 15px;
        font-weight: 500;
        width: 130px;
        height: 40px;
    }
    .initiative-box{
        padding: 22px 10px 0px 40px;
    }
    .h2-blog-line{
        font-weight: 800;
        margin-top: 80px;

    }
    .h-row-3{
        color: #1baa79;
        opacity: 1;
        height: 3px;
        width: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
    .image-div{
        width: 32%;
        border: 1px solid #e5dada;
        border-radius: 10px;
        padding: 0px;
    }
    .fifth-container-fluid{
        background-image: url("../../public/assets/images/Group\ 1284.png");
        height: auto;
        width: 100%;
        background-size: cover;
    }
    .taruk-div-width{
        width: 23%;
        border: 4px solid white;
        border-radius: 14px;
        padding: 20px 15px 30px 15px;
    }
    .div-container{
        padding: 80px 0px;
    }
    .conflict-para{
        line-height: 1.2;
    }
    .simran{
        background-color: #1baa79;
        padding: 22px;
        margin-bottom: 30px;
    }
    .read-more-2{
        border-radius: 0px;
        color: #1baa79;
        
    }
    .simran-img{
        margin-left: 60px;
    }
    .sixth-container-fluid{
        background: #4a4a4a;
        margin-top: 150px;
    }
    .simran-img-div{
        margin-top: -100px;
    }
    .leaf{
        width: 40px;
    }
    .p-footer{
        margin-top: 100px;
    }
    .div-img-footer{
        margin-top: 90px;
    }
    .div-footer{
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .footer{
        background: #4a4a4a;
    }
</style>
